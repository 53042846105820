<template>
  <PageContainer
    :full-width="appConfig.fullWidthHeroBlocks"
    :default-vertical-spacing="false"
    :default-horizontal-spacing="false"
    :height="PAGE_CONTAINER_HEIGHT.MD"
    class="relative"
  >
    <PageContainerBackground
      v-if="movie.heroImage"
      :background-gradient-bottom="true"
      :background-image="movie.heroImage"
      :background-image-position="movie.heroImageAlignment"
      :background-image-modifiers="appConfig.movieHeroImageModifiers"
      :preload="true"
    />
  </PageContainer>

  <PageContainer
    :full-width="appConfig.fullWidthHeroBlocks"
    :default-vertical-spacing="false"
    :default-horizontal-spacing="false"
    class="-mt-[84px]"
  >
    <div
      class="container-spacing-horizontal ~gap-4/6 ~-mt-0/44 container relative z-20 grid h-auto grid-cols-[auto,1fr] grid-rows-[1fr,auto] content-end"
    >
      <MoviePosterImage
        :image-object="posterImage"
        :image-config="appConfig.images.moviePage.poster"
        :rounded="ROUNDED_TYPE.NONE"
        class="mt-auto w-28 shrink-0 md:row-span-2 md:w-44"
      />

      <div class="~space-y-1.5/2 mt-auto text-pretty">
        <div v-if="movie.rating?.rating">
          <MovieRating :rating="movie.rating.rating" />
        </div>

        <h1
          class="~text-2xl/4xl max-w-2xl leading-tight lg:font-medium"
          v-text="movie.title"
        />

        <MovieFacts :movie />
      </div>

      <MovieHeadButtons
        :movie="movie"
        class="col-span-2 col-start-1 h-[42px] md:col-span-1 md:col-start-2"
      />
    </div>
  </PageContainer>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'
import { ROUNDED_TYPE } from '@base/constants/rounded'
import { PAGE_CONTAINER_HEIGHT } from '@base/constants/page-container'

interface Props {
  movie: Movie
}

const { movie } = defineProps<Props>()

const { t } = useI18n()
const appConfig = useAppConfig()

const posterImage = computed(() =>
  getImage(
    movie,
    'thumbnailImage',
    t('movie.posterImage', { movie: movie.title })
  )
)

defineOptions({
  name: 'MovieHead',
})
</script>

<i18n>
de:
  movie:
    posterImage: "Filmplakat von {movie}"
es:
  movie:
    posterImage: "Póster de {movie}"
</i18n>
