import type { FetchShowGroupsForMoviePlaytimesQueryVariables } from '#gql/default'

export default async function useShowGroups({
  operation,
  variables,
}: {
  operation: 'FetchShowGroupsForMoviePlaytimes'
  variables: Ref<FetchShowGroupsForMoviePlaytimesQueryVariables>
}) {
  const { data, status } = await useAsyncGql({ operation, variables })
  const showGroups = computed(() => data.value?.showGroups)

  const pending = computed(() => status.value === 'pending')

  return {
    showGroups,
    pending,
  }
}
