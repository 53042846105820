<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageMovie v-else-if="!pending && movie" :movie />
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from '@vueuse/router'

const urlSlug = useRouteParams('movieSlug', '', { transform: String })
const { page } = useCms()
const { movie, pending } = await useMovie({
  variables: { urlSlug: urlSlug.value },
})

if (!movie.value) {
  showError({
    statusCode: 404,
    statusMessage: 'movieNotFound',
  })
}

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]',
    de: '/film/[movieSlug]',
    es: '/pelicula/[movieSlug]',
  },
})

defineOptions({
  name: 'PageMovieMovieSlug',
})
</script>
