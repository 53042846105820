<template>
  <div class="contents">
    <GalleryHorizontal
      :slides="slides"
      :classes="{
        image: 'rounded-lg hover:cursor-pointer',
        container: 'rounded-lg',
      }"
      :image-size="GALLERY_IMAGE_SIZE.MD"
      :image-config="appConfig.images.moviePage.mediaGallery"
      :render-above-the-fold="true"
      :render-trailer="false"
      :allow-zoom="false"
      @slide-click="handleSlideClick"
    />

    <LazyModalMedia
      v-if="isGalleryModalVisible"
      :open="true"
      :aspect-ratio="16 / 9"
      @close="isGalleryModalVisible = false"
    >
      <GalleryCarousel
        :slides="slides"
        :active-slide="activeSlide"
        :render-above-the-fold="true"
        :render-trailer="true"
        :hide-title="true"
        :hide-description="true"
        :hide-link="true"
        :infinite="true"
        :autoplay="false"
        :slide-duration="5000"
      />
    </LazyModalMedia>
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

const props = defineProps<Props>()
const activeSlide = ref(0)
const isGalleryModalVisible = ref(false)
const { t } = useI18n()
const appConfig = useAppConfig()

function handleSlideClick(slideIndex: number) {
  activeSlide.value = slideIndex
  isGalleryModalVisible.value = true
}

const slides = computed(() => {
  const imageSlides = props.movie.sceneImages.map((_, index) => ({
    type: GALLERY_SLIDE_TYPE.IMAGE,
    image: getImage(
      props.movie,
      `sceneImages[${index}]`,
      t('movie.sceneImage', {
        movie: props.movie.title,
        index: index + 1,
      })
    ),
  }))

  const videoSlides = props.movie.trailers?.map((trailer) => ({
    type: GALLERY_SLIDE_TYPE.VIDEO,
    videoUrl: trailer.url,
    videoPlayerParams: {
      autoplay: false,
      mute: true,
      controls: true,
    },
  }))

  return [videoSlides, imageSlides].flat()
})

defineOptions({
  name: 'MovieMediaGallery',
})
</script>

<i18n>
de:
  movie:
    sceneImage: "Szene %{index} aus %{movie}"
es:
  movie:
    sceneImage: "Escena %{index} de %{movie}"
</i18n>
