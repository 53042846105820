<template>
  <div>
    <MoviePlaytimesDayGrid
      v-if="layout === CINEMAS_FOR_MOVIE_PLAYTIMES_LAYOUT.HORIZONTAL"
      :shows
      :link-params
      :booking-modal
    />

    <MoviePlaytimesDays v-else :shows :link-params :booking-modal />
  </div>
</template>

<script lang="ts" setup>
import type { Show, Cinema, LinkParam } from '#gql/default'

interface Props {
  cinema: Cinema
  movieId: string
  linkParams?: LinkParam[]
  dates?: string[]
  languageFlags?: string[]
  technologyFlags?: string[]
  bookingModal?: boolean
  layout?: CinemasForMoviePlaytimesLayout
}

const props = defineProps<Props>()

const { showGroups } = await useShowGroups({
  operation: 'FetchShowGroupsForMoviePlaytimes',
  variables: ref({
    movieId: props.movieId,
    cinemaId: props.cinema.id,
    dates: props.dates,
    languageFlags: props.languageFlags,
    technologyFlags: props.technologyFlags,
  }),
})

const shows = computed(
  () =>
    (showGroups.value?.data.flatMap((group) => group.shows?.data) ??
      []) as Show[]
)

defineOptions({
  name: 'CinemasForMoviePlaytimes',
})
</script>
