import type {
  Movie,
  FetchMovieDataForMoviePageQueryVariables,
} from '#gql/default'

export default async function useMovie({
  variables,
}: {
  variables: FetchMovieDataForMoviePageQueryVariables
}) {
  const { data, status } = await useAsyncGql(
    'FetchMovieDataForMoviePage',
    variables
  )

  const movie = computed(() => data.value?.movie as Movie | undefined)
  const pending = computed(() => status.value === 'pending')

  return {
    movie,
    pending,
  }
}
