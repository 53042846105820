<template>
  <div
    data-cinemas-for-movie-list
    class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y"
  >
    <template v-if="renderSkeletons">
      <CinemasForMovieListItemSkeleton v-for="(_, key) in skeletons" :key />
    </template>
    <template v-else>
      <CinemasForMovieListItem
        v-for="cinema in cinemas"
        :key="cinema.id"
        :movie-id
        :cinema
        :booking-modal
        :link-params
        :dates
        :technology-flags
        :language-flags
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Cinema, LinkParam } from '#gql/default'

interface Props {
  cinemas?: Cinema[]
  linkParams?: LinkParam[]
  bookingModal?: boolean
  renderAboveTheFold?: boolean
  renderSkeletons?: number
  hideLink?: boolean
  languageFlags?: string[]
  technologyFlags?: string[]
  dates?: string[]
  movieId: string
}

const props = defineProps<Props>()

const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

defineOptions({
  name: 'CinemasForMovieList',
})
</script>
