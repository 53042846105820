<template>
  <div
    class="py-4 transition-colors"
    :class="{
      'dark:hover:bg-light/5 hover:bg-dark/5': !isOpen,
    }"
  >
    <TransitionDropdown :is-open="isOpen">
      <template #trigger>
        <div class="flex space-x-4 hover:cursor-pointer" @click="toggle">
          <div class="my-auto">
            <div class="aspect-[1/1] h-[52px] w-[52px] overflow-hidden">
              <CinemaLogo
                :image-object
                :image-config
                :rounded="ROUNDED_TYPE.FULL"
              />
            </div>
          </div>

          <div class="dark:text-neutral-light-1 text-neutral-dark-1">
            <strong class="text-dark dark:text-light" v-text="cinema.name" />
            <div v-text="cinema.street" />
            <div v-text="getFormattedCity(cinema)" />
          </div>
        </div>
      </template>

      <template #dropdown>
        <CinemasForMoviePlaytimes
          :movie-id
          :cinema
          :dates
          :language-flags
          :technology-flags
          :booking-modal
          :link-params
          :layout="
            !!dates?.length
              ? CINEMAS_FOR_MOVIE_PLAYTIMES_LAYOUT.HORIZONTAL
              : CINEMAS_FOR_MOVIE_PLAYTIMES_LAYOUT.VERTICAL
          "
          class="pt-6"
        />
      </template>
    </TransitionDropdown>
  </div>
</template>

<script setup lang="ts">
import type { Cinema, LinkParam } from '#gql/default'

interface Props {
  cinema: Cinema
  movieId: string
  bookingModal?: boolean
  languageFlags?: string[]
  technologyFlags?: string[]
  dates?: string[]
  linkParams?: LinkParam[]
}

const { cinema } = defineProps<Props>()

const appConfig = useAppConfig()
const isOpen = ref(false)
const { $gtm } = useNuxtApp()

const imageObject = computed(() =>
  getImage(cinema, 'thumbnailImage', cinema.name)
)
const imageConfig = computed(() => appConfig.images.showGroup.cinemaLogo)

function toggle() {
  isOpen.value = !isOpen.value
  $gtm.push({
    event: 'toggle_cinema_list_item',
    cinema_name: cinema.name,
  })
}

defineOptions({
  name: 'CinemasForMovieListItem',
})
</script>
