<template>
  <div class="flex animate-pulse space-x-4 py-4 hover:cursor-pointer">
    <div class="my-auto">
      <div
        class="h-[52px] w-[52px] !rounded-full"
        :class="[cssClasses.color, cssClasses.text]"
      />
    </div>
    <div class="flex-grow space-y-2">
      <div
        class="h-4 w-48 rounded-full"
        :class="[cssClasses.color, cssClasses.text]"
      />
      <div
        class="h-3 w-24 rounded-full"
        :class="[cssClasses.color, cssClasses.text]"
      />
      <div
        class="h-3 w-36 rounded-full"
        :class="[cssClasses.color, cssClasses.text]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: 'CinemasForMovieListItemSkeleton',
})

const { cssClasses } = useSkeleton()
</script>
