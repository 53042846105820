<template>
  <slot :filter-options />
</template>

<script lang="ts" setup>
export interface FiltersDataProviderCinemaForMovieFetchParams {
  movieId: string
  cinemaIds?: string[]
  dates?: string[]
  showGroups?: string[]
  cinemaProximity?: {
    city: string
  }
}

interface Props {
  urlPrefix?: string
  filters?: UrlFilter[]
  fetchParams: FiltersDataProviderCinemaForMovieFetchParams
}

const COMPONENT_NAME = 'FiltersDataProviderCinemasForMovie'
const props = withDefaults(defineProps<Props>(), {
  filters: () => [],
  urlPrefix: '',
})

const { locale } = useI18n()

useGqlHeaders({ 'Accept-Language': locale.value })

const { data, execute, refresh } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.urlPrefix),
  () =>
    GqlFetchCinemasForMovieFilters({
      ...props.fetchParams,
      filters: props.filters,
    }),
  {
    immediate: false,
  }
)

await execute()

watch(
  () => props.fetchParams,
  async () => {
    await refresh()
  }
)

const filterOptions = data.value?.cinemasForMovie?.filterOptions ?? []

defineOptions({
  name: COMPONENT_NAME,
})
</script>
