<template>
  <div ref="dropdown">
    <slot name="trigger" />

    <div v-if="isOpen" :class="dropdownClasses">
      <slot name="dropdown" />
    </div>
  </div>
</template>

<script setup lang="ts">
import autoAnimate from '@formkit/auto-animate'
import type { AutoAnimateOptions } from '@formkit/auto-animate'

interface Props {
  buttonClasses?: string
  dropdownClasses?: string
  isOpen: boolean
  duration?: AutoAnimateOptions['duration']
  easing?: AutoAnimateOptions['easing']
  disrespectUserMotionPreference?: AutoAnimateOptions['disrespectUserMotionPreference']
}

const props = withDefaults(defineProps<Props>(), {
  buttonClasses: '',
  dropdownClasses: '',
  duration: 250,
  easing: 'ease-in-out',
  disrespectUserMotionPreference: false,
})

const dropdown = ref<HTMLElement>()

onMounted(() => {
  autoAnimate(dropdown.value!, {
    duration: props.duration,
    easing: props.easing,
    disrespectUserMotionPreference: props.disrespectUserMotionPreference,
  })
})

defineOptions({
  name: 'TransitionDropdown',
})
</script>
