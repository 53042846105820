import type { FetchCinemasForMovieQueryVariables, Cinema } from '#gql/default'

export default async function useCinemasForMovie({
  movieId,
  fetchOperation,
  fetchFallbackResults,
  filters,
  urlPrefix,
  preselected,
  first = 10,
}: {
  movieId: string
  fetchOperation: 'FetchCinemasForMovie'
  fetchFallbackResults: boolean
  filters?: UrlFilter[]
  urlPrefix?: string
  preselected: {
    search?: string
    city?: string
    dates?: string[]
    languageFlags?: string[]
    technologyFlags?: string[]
  }
  first?: number
}) {
  const { fetchParams, hasFiltersApplied } = useFetchParams({
    filters,
    urlPrefix,
    preselected,
  })

  const variables: Ref<FetchCinemasForMovieQueryVariables> = ref(
    getVariables(false, 1)
  )

  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const {
    results,
    pending,
    hasMorePages,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
  } = useFetchResults({
    data,
    status,
    fetchFallbackResults,
    fetchParams,
    variables,
    getVariables,
  })

  function getVariables(fallbackResults = false, page = 1) {
    const { search, city, dates, languageFlags, technologyFlags } =
      fetchParams.value
    const proximity = city ? { city, distance: 25 } : undefined
    const params = fallbackResults
      ? {}
      : { dates, search, languageFlags, technologyFlags }

    return {
      movieId,
      proximity,
      ...params,
      first,
      page,
    }
  }

  const cinemas = computed(() => results?.value as unknown as Cinema[])

  return {
    cinemas,
    pending,
    hasMorePages,
    hasFiltersApplied,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
    fetchParams,
  }
}
