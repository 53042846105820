<template>
  <div class="flex justify-center space-x-4 md:justify-start">
    <NuxtLink
      :to="`#${MOVIE_PAGE_SECTION_IDS.SHOW_CINEMA_LIST}`"
      :external="true"
      :title="t('btn.showPlaytimes')"
      class="button button-primary button-base w-full md:w-auto"
    >
      <InlineSvg :name="SVG_ICON.TICKET_CONFIRMATION_OUTLINE" />
      <span v-text="t('btn.tickets')" />
    </NuxtLink>

    <template v-if="movie.trailers?.[0]">
      <Button
        :theme="BUTTON_THEME.SECONDARY"
        class="w-full md:w-auto"
        :text="t('btn.trailer')"
        :title="t('btn.playTrailer')"
        :icon="SVG_ICON.PLAY"
        @click="isTrailerModalOpen = true"
      />

      <LazyYoutubeModal
        v-if="isTrailerModalOpen"
        :remote-video-id="movie.trailers[0].remoteVideoId!"
        :autoplay="true"
        :mute="false"
        :controls="true"
        @close="isTrailerModalOpen = false"
      />
    </template>

    <AccountWatchListButton v-if="appConfig.enableWatchList" :movie />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'
import { MOVIE_PAGE_SECTION_IDS } from '@base/constants/movie'
import { SVG_ICON } from '@assets/constants/inline-svg'
import { BUTTON_THEME } from '@base/constants/button'

interface Props {
  movie: Movie
}

defineProps<Props>()

const { t } = useI18n()
const isTrailerModalOpen = ref(false)
const appConfig = useAppConfig()

defineOptions({
  name: 'MovieHeadButtons',
})
</script>

<i18n>
de:
  btn:
    playTrailer: "Trailer abspielen"
    trailer: "Trailer"
    tickets: "Tickets"
    showPlaytimes: "Spielzeiten anzeigen"
es:
  btn:
    playTrailer: "Reproducir tráiler"
    trailer: "Tráiler"
    tickets: "Entradas"
    showPlaytimes: "Mostrar horarios"
</i18n>
